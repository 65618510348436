import React, {useState, useEffect, Fragment} from 'react';
import {mapStateToProps, mapDispatchToProps} from '../../Store/storeProps';
import {connect} from "react-redux";

function PortfolioPort({isScreenSize}) {

  const [style, setStyle] = useState(undefined);

  useEffect(()=>{

    if(isScreenSize.isChangedScreenSize>=1280){
      
      setStyle("Desktop")
    }
    else if(isScreenSize.isChangedScreenSize>=769 && isScreenSize.isChangedScreenSize<1280){
      
      setStyle("Laptop")
    }
    else if(isScreenSize.isChangedScreenSize<769 && isScreenSize.isChangedScreenSize>=481){
     
      setStyle("Phone")
    }
    else if(isScreenSize.isChangedScreenSize<481){
      
      setStyle("Phone")
    }
    else{
      
      setStyle(undefined)
    }

  },[isScreenSize])

  return (
   
    <Fragment>
      {
        style === undefined 
          ?
          <Fragment>
          </Fragment>
        :
        <div className={`portfolioPort_${style} dfrja`}>
          <p>
            The website is not designed to entertain visitors. 
            There are questions they think I can answer for them. 
            You can get the answers by unlocking the website.
          </p>
       </div>
      }
    </Fragment> 
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioPort)
