import React, { useState, useEffect, Fragment} from 'react';
import {mapStateToProps, mapDispatchToProps} from '../../Store/storeProps';
import {connect} from "react-redux";

function MainSocialMedia({isPassRight, socialList, getSocialListAsync, isDarkOrNormalMainLogoAsync, isScreenSize}) {
  
  const [style, setStyle] = useState(undefined);

  useEffect(()=>{

    if(isScreenSize.isChangedScreenSize>=1280){
      
      setStyle("Desktop")
    }
    else if(isScreenSize.isChangedScreenSize>=769 && isScreenSize.isChangedScreenSize<1280){
      
      setStyle("Laptop")
    }
    else if(isScreenSize.isChangedScreenSize<769 && isScreenSize.isChangedScreenSize>=481){
     
      setStyle("Phone")
    }
    else if(isScreenSize.isChangedScreenSize<481){
      
      setStyle("Phone")
    }
    else{
      
      setStyle(undefined)
    }

  },[isScreenSize])

  useEffect(() => {

    if(socialList === "Empty" && isPassRight){

      getSocialListAsync()
    }
  })

  const handleIN = ()=>{
  
     isDarkOrNormalMainLogoAsync(true);
  }

  const handleOut = ()=>{
    
    isDarkOrNormalMainLogoAsync(false)
 }

  return (
    
    <Fragment>
      {
        style === undefined 
          ?
          <Fragment>
          </Fragment>
        :
        <div className={`socialPlace_${style} dfrja`}>
          {
            socialList !== "Empty" && isPassRight
            ?
              socialList.map(social=>{
                return(
                  <div className={`socialicons_${style}`} key = {social.id} aftercontent={social.quote}>
                    <a href = {social.URL} target={social.target} rel="noreferrer noopener">
                      <i className = {social.class} onMouseEnter={handleIN} onMouseLeave={handleOut}></i>
                    </a> 
                  </div>
                )
              })
            :
            <Fragment></Fragment>
          }
        </div>
      }
    </Fragment> 
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(MainSocialMedia)
