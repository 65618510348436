import React, {useState, useEffect, Fragment} from 'react';
import "../../Styles/Main.css";
import "../../Styles/MainLogo.css";
import "../../Styles/MainInput.css";
import "../../Styles/MainPortfolio.css";
import "../../Styles/MainSocial.css"; 
import MainInput from './MainInput';
import MainLogo from './MainLogo';
import MainSocialMedia from './MainSocialMedia';
import PortfolioPort from './PortfolioPort';
import {mapStateToProps, mapDispatchToProps} from '../../Store/storeProps';
import {connect} from "react-redux";

function Main({isScreenSize}) {

  const [style, setStyle] = useState(isScreenSize);

  useEffect(()=>{

    if(isScreenSize.isChangedScreenSize>=1280){
      
      setStyle("Desktop")
    }
    else if(isScreenSize.isChangedScreenSize>=769 && isScreenSize.isChangedScreenSize<1280){
      
      setStyle("Laptop")
    }
    else if(isScreenSize.isChangedScreenSize<769 && isScreenSize.isChangedScreenSize>=481){
      
      setStyle("Phone")
    }
    else if(isScreenSize.isChangedScreenSize<481){
    
      setStyle("Phone")
    }
    else{

      setStyle(undefined)
    }

  },[isScreenSize])

  return (

    <Fragment>
      {
        style === undefined 
          ?
          <Fragment>
          
          </Fragment>
        :
          <div className={`Main_${style} dfcja`}>
          <MainLogo />
          <MainInput />
          <MainSocialMedia />
          <PortfolioPort />
        </div>
      }
    </Fragment> 
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Main)
