import React, {useState, useEffect, Fragment} from 'react';
import "../Styles/Footer.css";
import {mapStateToProps, mapDispatchToProps} from '../Store/storeProps';
import {connect} from "react-redux";

function Footer({isScreenSize}) {

  const [year, setYear] = useState(2022);
  const [companyName, setCompanyName] = useState("");
  const [secondCompanyName, setSecondCompanyName] = useState("");
  const [style, setStyle] = useState(undefined);

  useEffect(()=>{

    const date = new Date();
    const year = date.getFullYear();
    setYear(year);
    setCompanyName("Tiegrun")
    setSecondCompanyName("It's Designed By Me")

  }, [])

  useEffect(()=>{

    if(isScreenSize.isChangedScreenSize>=1280){
      
      setStyle("Desktop")
    }
    else if(isScreenSize.isChangedScreenSize>=769 && isScreenSize.isChangedScreenSize<1280){
      
      setStyle("Laptop")
    }
    else if(isScreenSize.isChangedScreenSize<769 && isScreenSize.isChangedScreenSize>=481){
     
      setStyle("Phone")
    }
    else if(isScreenSize.isChangedScreenSize<481){
      
      setStyle("Phone")
    }
    else{
      
      setStyle(undefined)
    }

  },[isScreenSize])

  return (
    
    <Fragment>
      {
        style === undefined 
          ?
          <Fragment>
          
          </Fragment>
        :
          <div className={`Footer_${style} dfcja`}>
            <h4 className={`copyRight_${style}`}>&copy; <a href="https://www.tiegrun.com/">{companyName}</a> | <a href="https://itsdesignedby.me/">{secondCompanyName}</a>  - {year}</h4>
          </div>
      }
    </Fragment> 
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
