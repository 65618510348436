import React, { useState, Fragment, useEffect } from 'react';
import { _mainPassword as mainPassword}  from "../../DATA/_data";
import {mapStateToProps, mapDispatchToProps} from '../../Store/storeProps';
import {connect} from "react-redux";

function MainInput({isRightPassAsync, lockCondition, isScreenSize}) {

  const [inputValue, setInputValue] = useState('');
  const [disableInput, setDisableInput] = useState(false);
  const [showInput, setShowInput] = useState(true);
  const [style, setStyle] = useState(undefined);

  useEffect(()=>{

    if(isScreenSize.isChangedScreenSize>=1280){
      
      setStyle("Desktop")
    }
    else if(isScreenSize.isChangedScreenSize>=769 && isScreenSize.isChangedScreenSize<1280){
      
      setStyle("Laptop")
    }
    else if(isScreenSize.isChangedScreenSize<769 && isScreenSize.isChangedScreenSize>=481){
     
      setStyle("Phone")
    }
    else if(isScreenSize.isChangedScreenSize<481){
      
      setStyle("Phone")
    }
    else{
      
      setStyle(undefined)
    }

  },[isScreenSize])

  useEffect(()=>{
    
    if(inputValue === mainPassword()){

      isRightPassAsync(true);

      setTimeout(()=>{
        setDisableInput(true);
        setInputValue("Correct !!!");
      }, 200)
    }
  })

  useEffect(()=>{

    if(disableInput){

      setTimeout(()=>{

        setShowInput(false)
      }, 1000)
    }
  },)

  const handleInput = (e) =>{

    const value = e.target.value;

    e.preventDefault();
    setInputValue(value)
  }

  return (
    
    <Fragment>
      {
        style === undefined 
          ?
          <Fragment>
          
          </Fragment>
        :
        <Fragment>
      {
        lockCondition && showInput
          ?
          <div className={`passArea_${style} dfcja`}>
              <input type="text" className={!disableInput ? `pass_${style}` : `passCorrect_${style}`} maxLength="6" placeholder='******' value={inputValue}  disabled={disableInput} onChange={handleInput} autoFocus />
            </div>
          :
            <div className={showInput ? `noPassArea_${style} dfrja` : `noPassAreaAnimation_${style} dfrja`}>
            </div>
      }
    </Fragment>

      }
    </Fragment> 
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(MainInput)
