import React, {useState, useEffect, Fragment} from 'react';
import "../Styles/Header.css";
import {mapStateToProps, mapDispatchToProps} from '../Store/storeProps';
import {connect} from "react-redux";

function Header({isLockedAsync, isPassRight, isScreenSize}) {

  const [btnName, setBtnName] = useState("Locked \u{1F512}");
  const [style, setStyle] = useState(isScreenSize);
  const [styleHeadOnlyPhone_Bgr, setStyleHeadOnlyPhone_Bgr] = useState("");
  const screenSize = isScreenSize.isChangedScreenSize;

  useEffect(()=>{

    if(screenSize>=1280){
      
      setStyle("Desktop")
    }
    else if(screenSize>=769 && screenSize<1280){
      
      setStyle("Laptop")
    }
    else if(screenSize<769 && screenSize>=481){
      
      setStyle("Phone")
    }
    else if(screenSize<481){
    
      setStyle("Phone")
    }
    else{

      setStyle(undefined)
    }

  },[isScreenSize])

  useEffect(()=>{

    if(!isPassRight){

      if(btnName === "Type Password \u{1F512}"){

        setTimeout(()=>{

          // console.log("Hhaha")


          screenSize < 769 ? setStyleHeadOnlyPhone_Bgr("Header_Phone_bgr_changed") : setStyleHeadOnlyPhone_Bgr("")
          
          isLockedAsync(true)
        }, 50) 
      }
      else{
  
        setTimeout(()=>{
  
          isLockedAsync(false)
        }, 50)
      }
    } 
    else{

      setTimeout(()=>{
  
        setBtnName("Unlocked \u{1F513}")
      }, 200) 
    } 
  })

  const refreshPage = () =>{

    window.location.reload(false);
  }

  const hoverEnter = (e) => {

    e.preventDefault();

    let btnText = e.target.innerHTML;

    if(!isPassRight && screenSize>=769){

      if(btnText === "Locked \u{1F512}"){

        setBtnName("Try To Unlock \u{1F512}")
      }
    }
  }

  const hoverLeave = (e) => {

    e.preventDefault();

    let btnText = e.target.innerHTML;

    if(!isPassRight && screenSize>=769){

      if(btnText === "Try To Unlock \u{1F512}"){

        setBtnName("Locked \u{1F512}")
      }
    }
  }

  const clickHandler = (e) => {

    e.preventDefault();

    let btnText = e.target.innerHTML;

    if(btnText === "Try To Unlock \u{1F512}" && screenSize>=769){

      setBtnName("Type Password \u{1F512}")
    }
    else if(btnText === "Locked \u{1F512}" && screenSize<769){

      setBtnName("Type Password \u{1F512}")
    }
    else if(btnText === "Unlocked \u{1F513}"){

      refreshPage()
    }
  }

  return (

    <Fragment>
      {
        style === undefined 
          ?
            <Fragment>
            </Fragment>
          :
            <div className={`Header_${style} ${styleHeadOnlyPhone_Bgr} dfcja`}>
              <button className={`lockedBtn_${style}`} onMouseEnter={hoverEnter} onMouseLeave={hoverLeave} onClick={clickHandler}>{btnName}</button>
            </div>
      }
    </Fragment> 
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)