import React, {useState, useEffect, Fragment} from 'react';
import PNG from "../../Images/Logo/PNG.png";
import {mapStateToProps, mapDispatchToProps} from '../../Store/storeProps';
import {connect} from "react-redux";

function MainLogo({isChangeMainLogoStyle, isPassRight, lockCondition, isScreenSize}) {

  const [logoStyle, setLogoStyle] = useState("main_logo");
  const [style, setStyle] = useState(undefined);

  useEffect(()=>{

    if(isScreenSize.isChangedScreenSize>=1280){
      
      setStyle("Desktop")
    }
    else if(isScreenSize.isChangedScreenSize>=769 && isScreenSize.isChangedScreenSize<1280){
      
      setStyle("Laptop")
    }
    else if(isScreenSize.isChangedScreenSize<769 && isScreenSize.isChangedScreenSize>=481){
     
      setStyle("Phone")
    }
    else if(isScreenSize.isChangedScreenSize<481){
      
      setStyle("Phone")
    }
    else{
      
      setStyle(undefined)
    }

  },[isScreenSize])

  useEffect(()=>{

    if(!lockCondition){

      setLogoStyle("main_logo")
    }
    else if(lockCondition && !isChangeMainLogoStyle){

      setLogoStyle("main_logo_dark")
    }
    else if(lockCondition && isChangeMainLogoStyle){

      setLogoStyle("main_logo_hovering")
    }
    else{

      setLogoStyle("main_logo")
    }

  }, [isChangeMainLogoStyle, isPassRight, lockCondition])

  return (
    
    <Fragment>
      {
        style === undefined 
          ?
          <Fragment>
          </Fragment>
        :
        <div className={`${logoStyle}_${style} dfrja`}>
          <div className={`Tiegrun_${style} dfcja`}>
            TIEG
          </div>
          <div className={`logo_Cont_${style}`}>
            <img className={`logo_${style}`} src={PNG} alt="logo" />
          </div>
          <div className={`Tiegrun_${style} dfcja`}>
            RUN
          </div>
         </div>
       }
    </Fragment>  
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(MainLogo)
