import React, {useEffect, useState} from 'react';
import './Styles/App.css';
import Header from './Components/Header';
import Main from './Components/Main/Main';
import Footer from './Components/Footer';
import Resume from './Components/Resume';
import Err from './Components/Err';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {mapStateToProps, mapDispatchToProps} from './Store/storeProps';
import {connect} from "react-redux";

function App({isChangedScreenSizeAsync}) {

  const [dimensions, setDimensions] = useState(window.innerWidth);

  const getSize = () => {

    const width = window.innerWidth

    setDimensions(width);
    
  }

  useEffect(() => {

    const handleResize = () => {

      window.addEventListener("resize", getSize, false);
    }

    handleResize()
  
  }, []);

  useEffect(()=>{

    const changedSizes = () =>{

      isChangedScreenSizeAsync(dimensions)
    }

    changedSizes()
    
  }, [isChangedScreenSizeAsync, dimensions])

  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="/" element={<><Header/><Main/><Footer /></>} />
          <Route path="resume" element={ <Resume/> } />
          <Route path="/*" element={ <Err/> } />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App)

